import React from 'react';

import {
  AuthorsContentHero,
  AuthorsContent,
} from '../components';

const Authors = () => {
  return (
    <div>
      <AuthorsContentHero />
      <AuthorsContent />
    </div>
  );
};

export default Authors;
