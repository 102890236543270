import React from 'react';


import { Howto } from '../components/Main/Articles';

const HowtoHandoverManscript = () => {
  return (
    <div>
      <Howto />
    </div>
  );
};

export default HowtoHandoverManscript;
