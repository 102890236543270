import React from 'react';


const AuthorsContent = () => {
  return (
    <div >
      <div >
        <p class="para-body1">
          My Library ආයතනය Digital ග්‍රන්ථ ප්‍රකාශන ආයතනයකි.එමෙන්ම නවක ලේඛක ලේඛිකාවන්ගේ ග්‍රන්ථ පල කිරීම මෙන්ම, මුද්‍රිත ග්‍රන්ථ Digital ග්‍රන්ථ ලෙස  පරිවර්තනය කර ප්‍රකාශයට පත් කිරීමෙහිද පුරෝගාමී වේ.</p>
      </div>
   
    </div>
  );
};

export default AuthorsContent;

