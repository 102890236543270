import React from 'react';

import {
  PublishWithUsContent,
  CommonHero,
} from '../components';


const PublishWithUs = () => {
  return (
    <div>
      <CommonHero title="Publish with My Library" />
      <PublishWithUsContent />
    </div>
  );
};

export default PublishWithUs;
