import React from 'react';
import { herobg } from '../../../assets';

const Howto = () => {
  return (
    <div className='text-justify sm:text-left'>
      <img src={herobg} alt="E-book" width="100%" />

      <div className="container pt-20">
        <h2 className="text-[#3157a3] leading-[1.6]">
          ඔබ විසින් රචිත අත් පිටපත පොත් ප්‍රකාශකයෙක්ට ඉදිරිපත් කිරීමට පෙර සලකා
          බැලිය යුතු කරුණු
        </h2>
        <br />
        <p className="text-[#090909] leading-8">
          ඔබත් යම් කාලයක් පුරාවට ඔබ විසින් රචනා කරන ලද අත් පිටපතක් ප්‍රකාශකයෙක්ට
          බාර දීමට බලාපොරොත්තුවෙන් සිටීද ? අත් පිටපතක් ප්‍රකාශකයෙක්ට ඉදිරිපත්
          කිරීමට පෙර එය තෝරා ගැනීමට ඇති අවස්ථාව අවම හෝ උපරිම කිරීමේ වගකීම ඇත්තේ
          ඔබ අතය.
        </p>
        <br /> <br />
        <h2 className="text-[#3157a3] leading-[1.6]">
          ඔබගේ අත් පිටපත කිහිපවාරයක් සංස්කරණය කරන්න
        </h2>

        <p className="text-[#090909] leading-8">
          අත්පිටපතක් ප්‍රකාශකයෙක්ට ඉදිරිපත් කිරීමට පෙර එය මනාව කිහිපවාරයක් කියවා
          සංස්කරණය කිරීම අනිවාර්ය වේ. අත් පිටපත ඔබට හැකි උපරිම අයුරින් වැරදි අවම
          කල එකක් විය යුතුය.
        </p>
        <p className="text-[#090909] leading-8">
          අත්පිටපතේ අන්තර්ගතය නව කථාවක් නම් පහත කරුණු සලකා බැලිය යුතුය.
        </p>

        <ul className="text-[#090909] leading-[1.6]">
          <li className="text-[#010101] leading-[1.6]">
            * මනාව ගලා යන කතා මාලාවක් තිබීම.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * කථාවේ සිද්ධි දාමයන් කථාව ගලා යාමට අවශ්‍ය වේද ? නොවේද යන බව.
            අනවශ්‍ය වැල් වටාරම් සිද්ධීන් සංස්කරණය කිරීම.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * අතීත, අනාගත, වර්තමාන කාල පරාස නිසි පරිදි පෙළ ගැස්වීම.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * කථාව කියන්නා නිසි පරිදි හඳුනාගැනීම. (උදා - මම විසින් හෝ වෙනත්
            පුද්ගලයෙක් විසින් හෝ පුද්ගල සම්බන්ධයක් රහිතව)
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * කථාවේ තීරණාත්මක සිදුවීම් නිසි පරිදි පෙළ ගස්වා තිබීම.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * ඔබේ ග්‍රන්ථය වෙනත් කාණ්ඩයකට අයත් නම් පොදුවේ පහත සඳහන් කරුණු සලකා
            බැලිය යුතුය.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * ඔබේ ග්‍රන්ථයේ සැකිල්ල තර්කානුකූලව පෙළ ගස්වා තිබීම.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * ඔබේ ඉලක්කගත පාඨකයින්ට ග්‍රන්ථය හරහා දැන ගැනීමට අවශ්‍ය කරුණු හා
            දැනුම නිසි පරිදි ලබා දෙන බව.
          </li>
          <li className="text-[#010101] leading-[1.6]">
            * ගැඹුරු විෂය කරුණු සහ නොගැඹුරු විෂය කරුණු නිසි පරිදි පෙළ ගැස්විම හා
            සමබරව ඉදරිපත් කිරීම.
          </li>
        </ul>
      </div>
      <br /> <br />
      <div className="container">
        <div>
          <h2 className="text-[#3157a3] leading-[1.6]">
            තවදුරටත් අත්පිටපත ගැන අවිශ්වාසනම්
          </h2>
        </div>
        <br />
        <p className="text-[#090909] leading-8">
          ඔබේ අත්පටපත යහපත් තත්වයේ පවතී යැයි තව දුරටත් ඔබට හැඟේ නම් වෘත්තීය පොත්
          නිවැරදි කරන්නෙක්ගේ (proof reader) සහය ලබා ගැනීම වඩාත් සුදුසුය. පවුලේ
          අය, මිතුරන් වැනි පාර්ශව වල සහය ලබා ගත්තද අත් පිටපත අවශ්‍ය පරිදි
          සංස්කරණය හා නිවැරදි කිරීමට සහය ලබා ගත හැකි වේ යැයි ස්ථීර වශයෙන් කිව
          නොහැක. නිසි වෘත්තිය නිපුණත්වය නොතිබීමත්, ඔබව අධෛර්ය කිරීට නොහැකි
          නිසාත් ඔවුන් බොහෝ විට ධනාත්මක අදහස් පමණක් දැක්වීමට නැඹුරු වේ.
        </p>
        <p className="text-[#090909] leading-8">
          මේ සඳහා අවශ්‍ය සහාය ඔබට 'My Library' ආයතනය වෙතින්ද ලබා ගත හැක. ඔබේ
          අත්පිටපත proof reader කරගැනීම සඳහා අප සමඟ සම්බන්ධ වන්න.
          <a href="#" >අමතන්න.</a>
        </p>
        <br />
        <div>
          <br />
          <div>
            <h2 className="text-[#3157a3] leading-[1.6]">
              සෝදු පත් කියවීම(proof read) හා අත් පිටපත සංස්කරණය කිරීම
            </h2>
          </div>
          <br />
          <p className="text-[#090909] leading-8">
            ඔබේ අත් පිටපතේ අන්තර්ගතය පිළිබඳ ඔබ තෘප්තිමත් නම් මීළඟට අවධානය යොමු
            කළ යුත්තේ අක්ෂර වින්‍යාසය හා ව්‍යාකරණ කෙරෙහිය. කාලයක් තිස්සේ අත්
            පිටපත සමඟ රැඳී හිඳීම නිසා බොහෝ විට කතුවරයාට ඇතැම් වැරදි අවධානයට ලක්
            නොවේ. ඒ නිසා වෘත්තිය proof reader කෙනෙක් ලවා මෙය කරවා ගැනීම වැදගත්ය.
            නිවැරදි බස හැසිරීම හා ව්‍යාකරණ හා අක්ෂර වින්‍යාසය ඔබේ අත් පිටපතට
            අමතර වටිනාකමක් ලබා දේ.
          </p>

          <div>
            <ul className="text-[#090909] leading-8">
              <li>* මේ වන විට අත් පිටපත වැරදීම් අඩු තත්වයක තිබී වැදගත්ය.</li>

              <li>
                * මුල් පිටුවේ පොතේ නම්, කතුවරයාගේ නම සහ සම්බන්ධ කරගැනීමේ විස්තර
                තිබීම අනිවාර්ය වේ.
              </li>

              <li>
                * ඔබේ අත් පිට කිසියම් විෂයකට අදාල නම් පරිච්ඡේද පෙළගස්වන ලද
                පිටුවක් තිබීම වැදගත්ය.
              </li>
              <li>
                * කියවීමට පහසු අත් අකුරු හෝ type කරන ලද පිටපතක් නම් කියවීමට පහසු
                font එකකින් තිබිය යුතුය.
              </li>
              <li>* Margin පැහැදිලිව තිබීම වැදගත්ය.</li>
              <li>* පිටු අංක නිවැරදිව සඳහන් කළ යුතුය.</li>
            </ul>
          </div>
        </div>
        <br />
        <br />
        <h2 className="text-[#3157a3] leading-[1.6]"> සාරාංශය</h2>
        <div>
          <p className="text-[#090909] leading-8">
            ඔබගේ අත් පිටපතට අදාල කතා සාරාංශය කෙටියෙන් වෙනම ලියා එවීමෙන්
            ප්‍රකාශකයාට පහසුවෙන් අත් පිටපත ගැන අවබෝධයක් ලබා ගැනීමට හැකියාව උදා
            වේ.
          </p>
        </div>
        <br /> <br />
        <h2 className="text-[#3157a3] leading-[1.6]"> ප්‍රකාශකයාගේ ප්‍රතිචාරය</h2>
        <div>

        
        <p class="para-body1">
        "කණගාටුයි ! අත් පිටපත භාර ගැනීමට නොහැක " වැනි හෝ කිසිදු ප්‍රතිචාරයක් ලැබීම ඉතා සුලභය. බොහෝමයක් අත් පිටපත් පළමු ප්‍රයත්නයේදී ප්‍රතික්ෂේප වීම සුලභය. අත් පිටපතේ ඇති වැරදි නිවැරදි කර තවදුරටත් සංස්කරණය කර නැවත උත්සහ කරන්න.
        <br />පොතක් ලිවීම යනු කලාවකි. බොහෝමයක් සාර්ථකත්වයට පත් ලේඛකයින්ගේ ඇතැම් කෘති ප්‍රකාශකයින් විසින් ප්‍රතික්ශේප කරන ලද අවස්ථා එමටය. ඇතැම් අවස්ථාවලදී කතෘ ප්‍රකාශනය ප්‍රතික්ශේප වීම් වලට විසඳුමක් විය හැක.
        <br />
ප්‍රකාශකයින් වෙත කතුවරයින් විසින් ලබා දෙන අත් පිටපත් (Digital හෝ physical ) සඳහා කිසිදු ආකාරයක වගකීමක් ප්‍රකාශකයින් විසින් දරනු නොලැබේ. එමෙන් කිසිදු ආකාරයේ වගකීමක් දැරීමට ප්‍රකාශකයින් බැඳී නැත.
</p>
          <p className="text-[#090909] leading-8">
            "කණගාටුයි ! අත් පිටපත භාර ගැනීමට නොහැක " වැනි හෝ කිසිදු ප්‍රතිචාරයක්
            ලැබීම ඉතා සුලභය. බොහෝමයක් අත් පිටපත් පළමු ප්‍රයත්නයේදී ප්‍රතික්ෂේප
            වීම සුලභය. අත් පිටපතේ ඇති වැරදි නිවැරදි කර තවදුරටත් සංස්කරණය කර නැවත
            උත්සහ කරන්න.
            <br />
            පොතක් ලිවීම යනු කලාවකි. බොහෝමයක් සාර්ථකත්වයට පත් ලේඛකයින්ගේ ඇතැම්
            කෘති ප්‍රකාශකයින් විසින් ප්‍රතික්ශේප කරන ලද අවස්ථා එමටය. ඇතැම්
            අවස්ථාවලදී කතෘ ප්‍රකාශනය ප්‍රතික්ශේප වීම් වලට විසඳුමක් විය හැක.
            <br />
            ප්‍රකාශකයින් වෙත කතුවරයින් විසින් ලබා දෙන අත් පිටපත් (Digital හෝ
            physical ) සඳහා කිසිදු ආකාරයක වගකීමක් ප්‍රකාශකයින් විසින් දරනු
            නොලැබේ. එමෙන් කිසිදු ආකාරයේ වගකීමක් දැරීමට ප්‍රකාශකයින් බැඳී නැත.

          </p>
        </div>
        <br /> <br /> <br />
      </div>
    </div>
  );
};

export default Howto;
