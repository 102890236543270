import React from 'react';

import { ebook, audiobook,epub,audio2,audio1,audio3 } from '../../../assets';


const PublishWithUsContent = () => {
  return (
    <div className='text-justify sm:text-left'>
      <div className="container pt-20">
        <div >
          <p class="para-body1">
            My Library ආයතනය Digital ග්‍රන්ථ ප්‍රකාශන ආයතනයකි.එමෙන්ම නවක ලේඛක ලේඛිකාවන්ගේ ග්‍රන්ථ පල කිරීම මෙන්ම, මුද්‍රිත ග්‍රන්ථ Digital ග්‍රන්ථ ලෙස  පරිවර්තනය කර ප්‍රකාශයට පත් කිරීමෙහිද පුරෝගාමී වේ.</p>
        </div>
        <div >
          <br />
          <br />
          <h2 class="para-body2" >Digital ප්‍රකාශන යනු කවරේද ?</h2>

          <p class="para-body1" >  අප විසින් එදිනෙදා පරිහරණය කරනු ලබන භෞතික පොත්, පුවත්පත් , සඟරා ආදිය පරිගණක, දුරකථන වැනි Digital උපකරණ ආශ්‍රයෙන් පරිහරණය කළ හැකි ආකාරයට සකස් කිරීම Digital ග්‍රන්ථ ලෙස හැඳින්වේ. Digital ග්‍රන්ථ ආකාර දෙකකි.
            <br />1. E books - ඉලෙක්ට්‍රොනික ග්‍රන්ථ
            <br />2. Audio books - ශ්‍රව්‍ය ග්‍රන්ථ
            Digital ග්‍රන්ථ වල විශේෂත්වය වනුයේ ඔබ සතුව ඇති ජංගම දුරකථනයක් හෝ වෙනත් Digital උපාංග භාවිතා කර කියවීමට හෝ ඇසීමට ඇති හැකියාවයි.
          </p>
          <p class="para-body1"> <a href='#'>Digital ග්‍රන්ථ ගැන වැඩිදුර කියවීම සඳහා පිවිසෙන්න.</a></p>
          <br />
          <br />
          <h2 class="para-body2" > ඔබ සතුව ඇති කතෘ ප්‍රකාශන, ප්‍රකාශන අයතිය සහිත ග්‍රන්ථ හෝ අත් පිටපත  ඩිජිටල් පොතක් බවට පරිවර්තනය කරන්නේ කෙසේද?</h2>

          <p class="para-body1">
            ඔබ සතුව ඇති කතෘ ප්‍රකාශන හෝ ප්‍රකාශන අයතිය සහිත ග්‍රන්ථ Electronic පොතක්(e book ) හෝ Audio ග්‍රන්ථයක්(audio book) බවට පරිවර්තනය කල හැක. මෙම පරිවර්තිත ග්‍රන්ථ My Library  Digital පුස්තකාලයට එකතු කිරීමෙන් අතිමහත් පාඨක සංඛ්‍යාවකට කියවීමට අවස්තාව ලැබේ . ලේඛක ලේඛිකාවන් විසින් ලබා දෙන ග්‍රන්ථ ඔවුන්ගේ සිතැඟි පරිදි e book, audio book ආකාරයට පරිවර්ථනයකරනු ලබන අතර මේ සඳහා වැය වන සම්පූර්ණ පිරිවැය My Library ආයතනය විසින් දරන ලැබේ.
          </p>

          <p class="para-body1">    ඔබ සතුව ඇති කතෘ ප්‍රකාශන හෝ ප්‍රකාශන අයිතිය සහිත ග්‍රන්ථ E book, Audio Book ආකාරයට පරිවර්තනය කිරීමේදී පහත සඳහන් පියවර අනුගමනය කෙරේ.
          </p>


        </div>

        <div >
          <div >
            <h2 class="para-body2" >පියවර 1 </h2>
          </div>
          <p class="para-body1"> ඔබ සතුව ඇති ග්‍රන්ථයේ මුද්‍රණය කරන ලද පිටපත හෝ type කරන ලද අවසන් පිටපත පහත සඳහන් පෝරමය සම්පූර්ණ කර My Library වෙත email හෝ තැපැල් මඟින් ලබා දිය හැක.
          </p>
          <p class="para-body1"> <a href='#'>Form එක මෙතැනින් download කරගන්න.</a></p>
          <div className='flex flex-col md:flex-row'>
            <ul class="para-body1 pl-0">
              <li class="para-body5" >1.email මඟින් ග්‍රන්ථ digital පරිවර්ථන සඳහා ලබාදීම.</li>
              <li class="para-body6" >* පෝරමය download කර පුරවා අත්සන් කර email එකට attach කරන්න. එය පැහැදිලිව .png, .jpeg, .pdf ආකාරයෙන් එකකින් තිබිය යුතුය.</li>
              <li class="para-body6">* ග්‍රන්ථය pdf හෝ .docx ආකාරයට අමුණන්න.</li>
              <li class="para-body6">* Subject ලෙස convert to digital ලෙස සඳහන් කර contact@mylibrary.com ලිපිනයට යොමු කරන්න</li>
            </ul>
            <ul class="para-body1 pl-0">
              <li class="para-body5">2.තැපැල් මඟින් එවීම</li>
              <li class="para-body6" >* පෝරමය download කර පුරවා අත්සන් කර ඔබේ ග්‍රන්ථයේ පිටපතක් හෝ අත් පිටපතක් පහත ලිපිනයට එවන්න.</li>
              <li class="para-body5">&#160;&#160;&#160;Post box 27</li>
              <li class="para-body5" >&#160;&#160;&#160;මාළඹේ</li>
              <li class="para-body6" >* ලිපියේ ඉහළ කෙළවර 'ග්‍රන්ථ digital පරිවර්ථනය' ලෙස සඳහන් කරන්න. ලියාපදිංචි තැපෑල වඩාත් සුදුසුයි.</li>
            </ul>
          </div>
          <div >
            <br />
            <div >
              <h2 class="para-body2" >පියවර 2</h2>
            </div>
            <p class="para-body1"> ඔබ විසින් එවන ලද පිටපත ලැබුණු විට my library ආයතනය විසින් ඔබව සම්බන්ධ කරගනු ලැබේ. ග්‍රන්ථය my library වෙත ඇතුලත් කිරීමට අදාල ගිවිසුම් පත්‍ර ඔබ වෙත ලබාදෙන අතර ගිවිසුම් අත්සන් කිරීමෙන් පසු ඔබගේ අවශ්‍යතාවය පරිදි e book, audio book ආකාරයට පරිවර්ථනය කර mobile app එකට ඇතුලත් කරනු ලැබේ.
              <br />  Digital මාධ්‍යට පරිවර්ථිත ග්‍රන්ථ කිසිදු හේතුවක් මත තුන්වන පාර්ශවයකට ලබා නොදෙන අතර පාඨකයන්ට ඒවා පරිශීලනය කළ හැක්කේ my library mobile app එක හරහා පමණි.කිසියම් නීතිමය හේතුවක් මත හෝ කිසියම් හේතුවක් නිසා ප්‍රතික්ශේප වන පිටපත් හෝ අත් පිටපත් නැවත හිමිකරු වෙත ආපසු එවනු ලැබේ.
            </p>

            <h2 class="para-body3">
              E books - ඉලෙක්ට්‍රොනික පොත් ලෙස පරිවර්ථනය  </h2>

            <p class="para-body1" >E book ලෙස පරිවර්ථනය කිරීමේදී අදාල ග්‍රන්ථය epub, txt, pdf යන ආකාර වලට පරිවර්ථනය කරනු ලැබේ.
            </p>
            <br />
            <table align='center'>
              <td  >
                <img name="Text Ebook" src={ebook} alt="E-book" align="center" width="270px" height="480px" ></img>
              </td>
              <td>
                <img name="Epub Ebook" src={epub} alt="E-book" align="center" width="270px" height="480px"></img>
              </td>
            </table>

            <br /> <br />

            <div>
           
            
             
              <ul class="para-body1" >
              
       

          <h2 class="para-body3">
            Audio book ලෙස පරිවර්ථනය</h2>
            <p class="para-body1">  Audio book යනු ශ්‍රවණය කල හැකි ආකරයට පටිගත කරන ලද පොත් වේ. </p>
            <div align="center">    <img src={audiobook} alt="Audio-book"  width="270px" height="480px" ></img>   </div>
          <p class="para-body1" >මෙහිදී ග්‍රන්ථය ශ්‍රව්‍ය ග්‍රන්ථ ආකාරයට පරිවර්තනය කරනු ලැබේ. අසන්නාට රුචි පරිදි කාන්තා කටහඬකින්  හෝ පිරිමි කටහඬකින් හෝ ශ්‍රව්‍යමය නාටකයක් ආකාරයට පටිගත කරන ලද ග්‍රන්ථ ශ්‍රවනය කල හැක. .</p>
          <ul class="para-body1" >
            <li >ආකාර 1 - පිරිමි කටහඬකින් පමණක් කියවීම.</li>
            <li > <figure>
  <figcaption>ඒ. සයිමන් ද සිල්වා ගේ මීනා නවකතාව - පළමුවන පරිච්ඡේදය</figcaption>

  <audio controls src={audio1}></audio>
</figure></li>
            <li >ආකාර 2 - ගැහැණු කටහඬකින් පමණක් කියවීම.</li>
            <li >  <figure>
  <figcaption>ඒ. සයිමන් ද සිල්වා ගේ මීනා නවකතාව - පළමුවන පරිච්ඡේදය</figcaption>
   <audio controls src={audio2}></audio>
 
</figure></li>
         
            <li >ආකාර 3 - ශ්‍රව්‍යමය නාටකයක් ආකාරයට කියවීම.
              මෙහිදී විවිධ චරිත සඳහා විවිධ කටහඬ සහිත නළු නිලියන් යොදා පටිගත කරනු ලැබේ.</li>
              <li >    <figure>
  <figcaption>ලස්සන වසීලිස්සා</figcaption>
  <audio controls src={audio3}></audio>
</figure></li>
          </ul>
              </ul>

            </div>
          </div>

          <br /> <br />
          <h2 class="para-body2" >  My library වෙත එකතු කරන ලද ග්‍රන්ථ සඳහා ගෙවීම් කිරීම.</h2>
          <div>
            <ul class="para-body1">
              <li >ඔබගේ ග්‍රන්ථය my library app වෙත ගෙවීමක් සහිතව හා නොමිලේ යන කාණ්ඩ 2 යටතේ ලබා දිය හැකිය.</li>
              <li >නොමිලේ කාණ්ඩය යටතේ ලබාදෙන ග්‍රන්ථ ඕනෑම My library පාඨකයෙක්ට නොමිලේ කියවිය හැකි අතර එය ඔබ විසින් දැයට පිදෙන තිලිණයක් ලෙස සැලකේ. එවැනි ග්‍රන්ථ සඳහා My library ආයතනය කිසිදු ආකාරයක අයකිරීමක් ලබා නොගනී.</li>
              <li >ගෙවීම් කාණ්ඩය යටතේ ලබාදෙන ග්‍රන්ථ සඳහා අදාල මාසය තුල එම ග්‍රන්ථය කියවන ලද පාඨකයින් විසින් ගෙවන ලද සාමාජික ගාස්තුවෙන් 60% ක ප්‍රමාණයක් කතෘ වෙත ලබාදෙන අතර 40% ක ප්‍රමාණයක් My library ආයතනය වෙත අය කරගනු ලැබේ.</li>
            </ul>
          </div>
          <br /> <br />


        </div>
      </div>
    </div>
  );
};



export default PublishWithUsContent;

